import React from 'react';
import PropTypes from 'prop-types';
import generalFailedPC from '../../fetch/generalFailedPC';
import withDataHOC from '../../dataProvider/withDataHOC';
import Tooltip from '../../general/Tooltip';
import FormModal from '../../fetch/FormModal';
import generalNoParameterFormPC from '../../fetch/generalNoParameterFormPC';
import {locationHOC} from '../../locationProvider/locationHOC';
import {Order, OrderDetail, ButtonSize} from '../../../constants/propTypesDefinitions';
import {GLOBAL_DATA} from '../../../constants/globalData';
import {isOrderSend} from '../../../lib/project';
import {createFetchOrderRemove} from '../../../backend/apiCalls';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */

class RemoveOrder extends React.PureComponent {
    static propTypes = {
        order: PropTypes.oneOfType([Order.isRequired, OrderDetail.isRequired]).isRequired,
        size: ButtonSize,
        buttonContent: PropTypes.node,
        onFinishSuccessful: PropTypes.func,
        buttonClassName: PropTypes.string,
    };

    onFinishSuccessful = () => {
        const {onFinishSuccessful} = this.props;
        if (onFinishSuccessful != null) {
            onFinishSuccessful();
        }
    };

    render() {
        const {order, buttonContent, size, buttonClassName} = this.props;
        const disabled = isOrderSend(order);
        return <FormModal
            disabled={disabled}
            openNode={
                <Tooltip
                    title={disabled ? 
                        <Trans>Odoslanú objednávku nemožno odstrániť</Trans> :
                        (buttonContent != null ? null : <Trans>Odstrániť</Trans>)
                    }
                >
                    {
                        buttonContent == null ?
                            <Button
                                className={buttonClassName ? buttonClassName : ''}
                                size={size}
                                type="danger"
                                icon="close"
                                disabled={disabled}
                            />
                            :
                            <Button
                                className={buttonClassName ? buttonClassName : ''}
                                size={size}
                                type="danger"
                                disabled={disabled}
                            >
                                {buttonContent}
                            </Button>
                    }
                </Tooltip>
            }
            values={{}}
            onFinishSuccessful={this.onFinishSuccessful}
            title={<Trans>Odstránenie rozpracovanej objednávky</Trans>}
            Form={generalNoParameterFormPC(
                <Trans>Chcete odstrániť rozpracovanú objednávku?</Trans>,
                createFetchOrderRemove(order.id),
            )}
            Response={null}
            Failed={generalFailedPC(t`Nepodarilo sa odstrániť objednávu.`)}
        />;
    }

}

export default locationHOC(withDataHOC([GLOBAL_DATA.RELOAD_DATA])(RemoveOrder));