import React from 'react';
import PropTypes from 'prop-types';

import Helmet from '../project/Helmet';
import withDataHOC from '../dataProvider/withDataHOC';
import OrdersParams from './orders/OrdersParams';
import OrdersTable from './orders/OrdersTable';
import {GLOBAL_DATA} from '../../constants/globalData';
import {Order} from '../../constants/propTypesDefinitions';
import TableWithFiltersLayout from '../project/TableWithFiltersLayout';
import {Trans, t} from '@lingui/macro';
import { QUERY_PARAMS } from '../../constants/navigation';

/**
 * @fero
 */

class OrdersPage extends React.PureComponent {
    static propTypes = {
        [GLOBAL_DATA.ORDERS]: PropTypes.arrayOf(Order.isRequired).isRequired,
        [GLOBAL_DATA.RELOAD_DATA]: PropTypes.func.isRequired,
    };


    render() {
        const {[GLOBAL_DATA.ORDERS]: orders, [GLOBAL_DATA.RELOAD_DATA]: reload} = this.props;
        return <React.Fragment>
            <Helmet
                title={t`Objednávky`}
            />
            <TableWithFiltersLayout
                title={<Trans>Objednávky</Trans>}
                params={<OrdersParams/>}
                table={<OrdersTable
                    orders={orders}
                    reload={() => reload([GLOBAL_DATA.ORDERS])}
                />}
                scrollParam={QUERY_PARAMS.ORDER_SCROLL}
            />
        </React.Fragment>;
    }
}

export default withDataHOC([GLOBAL_DATA.ORDERS, GLOBAL_DATA.RELOAD_DATA])(OrdersPage);